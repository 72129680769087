import React from 'react';
import { Layout, Seo } from 'components';
import { Col, Row } from "react-grid-system";


// import page style
import './contact-us.scss';

const ContactUs = () => {

  return (
    <Layout indication="patient" className="contact-us">
      <Seo pageTitle="Contact Info"
      pageDescription="Find contact information for medical inquiries regarding Butrans. See Full Prescribing Info, Safety Info & Boxed Warning." />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <Row>
            <Col xs={12}>
              <h1>Contact Us</h1>
              <ul className="phone-list">
                <li>Main Phone Number</li>
                <li><a href="tel:+12035888000">1-203-588-8000</a></li>
                <li>Medical Inquiries/Drug Safety</li>
                <li><a href="tel:+18887267535">1-888-726-7535</a></li>
                <li>Customer Service</li>
                <li><a href="tel:+18008775666">1-800-877-5666</a></li>
                <li>Public Affairs/Media Relations</li>
                <li><a href="tel:+12035888069">1-203-588-8069</a></li>
                <li>Ethics and Compliance Hotline</li>
                <li><span className="text-bold"><a href="tel:+1-877-PURDUE1" className="text-black">1-877-PURDUE1</a> (or <a href="tel:+18777873831" className="nowrap">1-877-787-3831</a>) toll free</span>, 24 hours a day, to report any suspected violations of ethics, law or Purdue policy. Callers may choose to remain anonymous and will be protected from retaliation in any form. Alternatively, callers may email Purdue Ethics &amp; Compliance at <a href="mailto:compliance@pharma.com" className="text-no-underline">compliance@pharma.com</a>.</li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
